import { OTHER_CUSTOMER_SUPER_ADMIN } from '@/temlplates';
import mixinsAlert from '@/components/common/mixinsAlert';

export const mixinLinkAccount = {
	data() {
		return {
			cookieKeys: {
				openloungeKey: 'openlounge-code',
				daouofficeKey: 'daouoffice-code',
				memberInviteKey: 'invite-code',
			},
		};
	},
	mixins: [mixinsAlert],
	methods: {
		async linkService() {
			const daouofficeCode = this.$store.getters['getDaouofficeCode'];
			if (daouofficeCode) {
				this.clearLinkCodeInStore();
				await this.$store
					.dispatch('linkDaouOffice', daouofficeCode)
					.then(() => this.$store.commit('CLEAR_LINK_CODES'))
					.catch((e) => {
						if (e.data?.errorCode === 'ERROR0702') {
							this.alert({ contents: OTHER_CUSTOMER_SUPER_ADMIN });
							return;
						}
						this.$toasted.show('기업 초대가입에 실패했습니다.');
					});
				return;
			}
			const openloungeCode = this.$cookies.get(this.cookieKeys.openloungeKey); //@TODO: 추후에 오픈라운지 도입 시 수정 필요
			if (openloungeCode) {
				this.clearLinkCodeInStore();
				await this.$store
					.dispatch('linkOpenLounge', openloungeCode)
					.then(() => this.$cookies.remove(this.cookieKeys.openloungeKey));
				return;
			}
			// const inviteCode = this.$cookies.get(this.cookieKeys.memberInviteKey);
			const inviteCode = this.$store.getters['getInviteCode'];
			if (inviteCode) {
				this.clearLinkCodeInStore();
				await this.$store
					.dispatch('linkCustomerWithUser', inviteCode)
					.then(() => {})
					.catch((e) => {
						if (e.data?.errorCode === 'ERROR0702') {
							this.alert({ contents: OTHER_CUSTOMER_SUPER_ADMIN });
							return;
						}
						this.$toasted.show('기업 초대가입에 실패했습니다.');
						// if (e.data?.errorCode === 'ERROR0702') {
						// 	this.alert({ contents: OTHER_CUSTOMER_SUPER_ADMIN });
						// }
					});
			}
		},
		clearLinkCodeInStore() {
			this.$store.commit('CLEAR_LINK_CODES');
		},
	},
};
