<template>
	<div></div>
</template>
<script>
import { getDetermineOriginUri } from '@/utils/cookies';
import mixinsAlert from '@/components/common/mixinsAlert';
import { SNS_ACCOUNT_CI_NOT_EXIST, SNS_LINK_CI_NOT_EXIST } from '@/temlplates';
import { mixinLinkAccount } from '@/components/login/mixinsLinkAccount';

export default {
	name: 'SnsAccountRedirect',
	mixins: [mixinsAlert, mixinLinkAccount],
	methods: {},
	created() {
		let determine_origin_uri = getDetermineOriginUri();
		if (!determine_origin_uri) {
			this.linkService().finally(() => this.$router.push('/home'));
		}

		// TODO: 오리진 uri 판별
		if (determine_origin_uri.includes('signup')) {
			this.alert({
				contents: SNS_ACCOUNT_CI_NOT_EXIST,
				buttons: [
					{
						text: '회원가입',
						callback: async () => {
							await this.$router.push('/signup');
						},
					},
				],
			});
			this.$router.push('/login');
		} else if (determine_origin_uri.includes('login')) {
			this.alert(SNS_ACCOUNT_CI_NOT_EXIST);
			this.$router.push('/login');
		} else if (determine_origin_uri.includes('sns')) {
			this.alert(SNS_LINK_CI_NOT_EXIST);
			this.$router.push('/profile');
		}
	},
};
</script>

<style scoped>
@import '../../assets/css/common.css';
@import '../../assets/css/login.css';
</style>
